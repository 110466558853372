const menu = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'About',
        path: '/about'
    },
    {
        name: 'Therapies',
        path: '/therapies'
    },
    {
        name: 'Contact',
        path: '/contact'
    }

];

export default menu;
